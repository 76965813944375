import React, { useEffect } from 'react';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'

import Container from '@mui/material/Container';

import Home from './pages/Home';
import Research from './pages/Research';
import Coalition from './pages/Coalition';
import Example from './pages/Example';

import Header from './fragments/Header';
import Footer from './fragments/Footer';

// export const API_BASE = 'http://localhost:8080';
export const API_BASE = 'https://api-dot-dqfit-dev.uc.r.appspot.com';

export default function App() {

  const healthCheck = async (event) => {
    try {
      const response = await axios.get(`${API_BASE}/health`)
    } catch (error) {
      console.error('There was an error!', error);
    }

  };

  // warm up endpoint
  useEffect(() => {
    healthCheck();
  }, []);


  return (
    <>
      <Header />
      <Container maxWidth='xl' style={{ minHeight: '80vh' }}>
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/example' element={<Example />} />
            <Route path='/research' element={<Research />} />
            <Route path='/coalition' element={<Coalition />} />
          </Routes>
        </Router>
      </Container>
      <Footer />
    </>
  );
}
