import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import WhatshotIcon from '@mui/icons-material/Whatshot';

const goto = (url) => {
  console.log("goto", url);
  window.location.href = url;
}

export default function Home() {

  return (
    <Box id='home' style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '50vh', padding: '150px 0' }}>
      
  
      <Typography variant="h4" component="div" gutterBottom>
        Are your data safe and effective?
      </Typography>
      <Typography variant='subtitle1' >
        Measuring data quality and fitness-for-use of HL7 FHIR Resources.
      </Typography>
      

      <Box>
        <Button
          variant="contained"
          style={{ margin: '20px' }}
          onClick={() => goto("/example")}
          startIcon={<WhatshotIcon />}
          size='large'
        >
          Find Out
        </Button>
      </Box>

    </Box>

  );
}
