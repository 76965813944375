import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import WhatshotIcon from '@mui/icons-material/Whatshot';



export default function Coalition() {

  const members = [
    "Amol Vyas",
    "Jonathan Nebeker, MD",
    "Brad Ryan, MD",
    "Marc Overhage, MD, PhD",
    "Ed Yuricisin",
    "Aneesh Chopra",
    "Mike Berger",
    "Ben Hamlin, DrPH",
    "Rob Currie",
    "Beau Norgeot, PhD",
    "Arjun Sanyal",
    "Dede Ainbinder",
    "Rishi Bhayana",
    "Evan Machusak",
    "Sandi Mitchell",
    "Sheryl Turney",
    "Kenji Wong",
    "Parker Holcomb",
    "John Anderson, MD",
    "Steve Yaskin",
    "Sergio Garcia",
    "Steven Lane, MD",
    "You!"
  ]

  const sortedMembers = members.sort();




  return (

    <Container style={{ flex: 1, minHeight: '80vh' }}>

      <Typography variant='h4' style={{ marginTop: '25px' }}>
        Data Quality Coalition
      </Typography>


      <Box style={{ display: 'flex', direction: 'row' }}>
        <Box style={{ minWidth: 300 }}>
          <Box style={{ margin: '15px 0' }}>
            <Typography variant='caption' >
              listed alphabetically
            </Typography>
          </Box>

          {
            sortedMembers.map((member, i) => {
              return (
                <Typography variant='body1' key={i}>
                  {member}
                </Typography>
              )
            })
          }
        </Box>
        <Box>
          <img src='/images/coalition.png' alt="logo" style={{ marginRight: '10px', width: '100%' }} />
        </Box>
      </Box>



    </Container>

  );
}
