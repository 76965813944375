import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

import AddIcon from '@material-ui/icons/Add';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GroupsIcon from '@mui/icons-material/Groups';

import PathLevelTable from '../fragments/PathLevelTable';
import ResultTable from '../fragments/ResultTable';
import ModelSettingsContainer from '../fragments/ModelSettingsContainer';

// import syntheaBundle from '../data/SyntheaBundle.json';
// import syntheaBulk from '../data/SyntheaBulk.ndjson'; // consolidated to one file for demo

import { API_BASE } from '../App';

const contextOptions = {
  "example": <><Chip label="base_context" size='small' variant="contained" color='primary' style={{ margin: '0 15px' }} /></>,
  "COLE": <><Chip label="HEDIS" size='small' color='secondary' variant='outlined' style={{ margin: '0 15px' }} /></>,
  "risk-adjustment": <><Chip label="MA" size='small' color='secondary' variant='outlined' style={{ margin: '0 15px' }} /></>,
  "systolic": <><Chip label="Lab" size='small' color='primary' variant='outlined' style={{ margin: '0 15px' }} /></>,
  "a1c": <><Chip label="Lab" size='small' color='primary' variant='outlined' style={{ margin: '0 15px' }} /></>,
  "patient-equity": <><Chip label="SDOH" size='small' color='secondary' variant='outlined' style={{ margin: '0 15px' }} /></>,
}


export default function Example() {

  const [fhirResources, setFhirResources] = useState([]);
  const [model, setModel] = useState('dqi-3');
  const [context, setContext] = useState('example');
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadSampleBundle = async () => {
    const response = await axios.get('/SyntheaBundle.json')
    setFhirResources(JSON.stringify(response.data, null, 4));
  }

  const loadSampleBulk = async () => {
    const response = await axios.get('/SyntheaBulk.ndjson')
    setFhirResources(response.data);
  }

  const parseFhirResources = () => {
    try {
      // Check if the content is NDJSON
      if (fhirResources.trim().startsWith('{') && fhirResources.includes('\n{')) {
        console.log('NDJSON detected');
        const lines = fhirResources.split('\n');
        const parsedData = lines.map(line => JSON.parse(line));
        return parsedData
      }
      // If not NDJSON, parse as regular JSON
      return JSON.parse(fhirResources);
    } catch (error) {
      console.error('There was an error!', error);
      return null;
    }
  };

  const handleClick = async (event) => {
    try {
      setLoading(true);
      const parsedData = parseFhirResources();
      console.log(parsedData);
      const response = await axios.post(`${API_BASE}/dqfit-rest`,
        {
          "model": model,
          "context": context,
          "fhir_resources": parsedData
        })

      setResult(response.data);
      setError(null)
      console.log(result);
    } catch (error) {
      setError(error.message)
      console.error('There was an error!', error);
    } finally {
      setLoading(false);
    }

  };


  return (
    <Box >
      <Box id='example-container'>

        {
          result && (
            <Box id='show-results'>
              <Button
                variant="outlined"
                onClick={() => setResult(null)}
                color='primary'
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    '& .error': {
                      backgroundColor: '#f6685e',
                    },
                    '& .warning': {
                      backgroundColor: '#ffac33',
                      color: '#000',
                    },
                  }}
                  style={{ marginRight: '50px', flexGrow: 1 }}
                >
                  <ResultTable result={result} model={model} />
                  <PathLevelTable result={result} model={model} />
                </Box>
                <Box style={{ marginTop: '25px', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
                  <ModelSettingsContainer
                    context={context}
                    setContext={setContext}
                    model={model}
                    loading={loading}
                    setModel={setModel}
                    error={error}
                    handleClick={handleClick}
                    contextOptions={contextOptions}
                  />

                  {/* <Box id='data-quality-dimensions' style={{ marginTop: '150px' }}>
                    <Typography variant='h6'>Data Quality Dimensions</Typography>
                    
                    <Typography variant='body1'>
                      Conformant: Is the path in the context?
                    </Typography>
                    <Typography variant='body1'>
                      Complete: absence / presence without regards to value.
                    </Typography>
                      
                    <Typography variant='body1'>
                      Plausible:
                    </Typography>

                  </Box> */}

                </Box>
              </Box>
            </Box>


          )}

        {
          !result && (
            <Box id="input">
              <Paper style={{ margin: '25px 0', padding: '25px', background: '#F8F8F8' }} align='center'>
                <Typography>
                  The following is a research preview reference implementation of a data quality index that measures quality and fitness-for-use in clinical data.
                </Typography>
              </Paper>
              <Box id='form' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box id='fhir-input' style={{ marginRight: '50px', flexGrow: 1 }}>

                  <Box id='add-sample-fhir-btn-row' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>


                    <Box>
                      <Tooltip title="Don't have FHIR? Get started with a Simple Bundle generated from Synthea">
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => loadSampleBundle()}
                          startIcon={<PersonAddAlt1Icon />}
                          style={{ margin: '10px 25px 10px 0', background: '#8A25D2' }}
                        >
                          Patient / Bundle
                        </Button>
                      </Tooltip>
                      <Tooltip title="Don't have FHIR? Get started with Bulk Data generated from Synthea">
                        <Button
                          color='secondary'
                          variant='contained'
                          onClick={() => loadSampleBulk()}
                          style={{ margin: '10px 25px 10px 0' }}
                          startIcon={<GroupsIcon />}
                        >
                          Population / Bulk
                        </Button>
                      </Tooltip>
                    </Box>

                  </Box>


                  <TextField
                    id="fhir-resources"
                    label="FHIR Resource(s)"
                    variant="outlined"
                    value={fhirResources}
                    multiline
                    rows={20}
                    style={{ margin: '25px 0 25px 0', width: '100%' }}
                    onChange={(event) => setFhirResources(event.target.value)}
                    inputProps={{ style: { flexGrow: 1, fontSize: "0.8em" } }} // adjust height here
                    helperText="json/ndjson"
                  />

                </Box>
                <ModelSettingsContainer
                  context={context}
                  setContext={setContext}
                  model={model}
                  loading={loading}
                  setModel={setModel}
                  error={error}
                  handleClick={handleClick}
                  contextOptions={contextOptions}
                />
              </Box>


            </Box>
          )
        }



      </Box>
    </Box>
  );
}
