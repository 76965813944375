import * as React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddIcon from '@material-ui/icons/Add';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export default function ModelSettingsContainer({ context, setContext, model, setModel, loading, error, setError, handleClick, contextOptions }) {


  return (
    <Box id='settings-container' >
      <Typography variant='h6' >
        Model Settings:
      </Typography>
      <Box id='model-form' style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center' }}>
        <Select
          value={model}
          onChange={(event) => setModel(event.target.value)}
          label="Model"
          displayEmpty
          fullWidth
          style={{ margin: '15px 0 25px 0' }}
        >
          <MenuItem value="" disabled>
            Model
          </MenuItem>
          <MenuItem value={"dqi-2"} key="dqi-2">
            dqi-2
            <Chip label="Conformant" style={{ marginLeft: '15px' }} size='small' />
            <Chip label="Complete" style={{ marginLeft: '5px' }} size='small' />
          </MenuItem>
          <MenuItem value={"dqi-3"} key="dqi-3">
            dqi-3
            <Chip label="Conformant" style={{ marginLeft: '15px' }} size='small' />
            <Chip label="Complete" style={{ marginLeft: '5px' }} size='small' />
            <Chip label="Plausible" style={{ marginLeft: '5px' }} size='small' />
          </MenuItem>
          <MenuItem value={"dqi-4"} key="dqi-4">
            dqi-4
            <Chip label="Conformant" style={{ marginLeft: '15px' }} size='small' />
            <Chip label="Complete" style={{ marginLeft: '5px' }} size='small' />
            <Chip label="Plausible" style={{ marginLeft: '5px' }} size='small' />
            <Chip label="Current" style={{ marginLeft: '5px' }} size='small' />
          </MenuItem>
        </Select>

        <Select
          value={context}
          onChange={(event) => setContext(event.target.value)}
          fullWidth
          displayEmpty
          style={{ margin: '15px 0 25px 0' }}
        >
          <MenuItem value="" disabled>
            Context
          </MenuItem>
          {
            Object.entries(contextOptions).map(([key, value]) => (
              <MenuItem key={key} value={key} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{key}</span> <span>{value}</span>
              </MenuItem>
            ))
          }
        </Select>
        {error && (
          <Box>
            <Typography color="error">{error}</Typography>
          </Box>
        )}

        {
          loading ? (
            <CircularProgress />
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={handleClick}
              color='primary'
              startIcon={<PlayArrowIcon />}
            >
              Run Model
            </Button>
          )
        }

      </Box>

      

    </Box>
  )
}