import * as React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { DataGrid } from '@mui/x-data-grid';


export default function ResultTable({ result, model }) {

    const getColumns = () => {
        const columns = [
            {
                field: 'path',
                headerName: 'path',
                width: 200,
                flex: 1
            },
            {
                field: 'weight',
                width: 90,
                type: 'number',
            },
            {
                field: 'patient_count',
                type: 'number',
                width: 120,

            },
            {
                field: 'resource_count',
                type: 'number',
                width: 120,

            },

            {
                field: 'Conformant',
                type: 'number',
                width: 120,
            },
            {
                field: 'Complete',
                type: 'number',
                width: 120,
            }

        ];
        if (model === 'dqi-3' || model === 'dqi-4') {
            columns.push({
                field: 'Plausible',
                type: 'number',
                width: 90,
            })
        }

        if (model === 'dqi-4') {
            columns.push({
                field: 'Current',
                type: 'number',
                width: 90,
            })
        }
        columns.push({
            field: 'Score',
            type: 'number',
            width: 90,
        })
        return columns;
    }

    const rows = result['population_level']

    return (
        <Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between', margin: '25px 0' }}>
                <Typography variant="h4">
                    Data Quality Index: <span style={{textDecoration: 'underline'}}>{parseFloat(result['index']).toFixed(1)}</span>
                    
                </Typography>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between', margin: '25px 0' }}>
                <Typography variant="h6">
                    Population Level:
                </Typography>
            </Box>
            <DataGrid
                autoHeight
                density='compact'
                getRowId={(row) => row.id + row.path}
                rows={rows}
                columns={getColumns()}
                pageSizeOptions={[25, 50, 100]}
                initialState={{
                    pagination: { paginationModel: { page: 0, pageSize: 25 } },
                    sorting: { sortModel: [{ field: 'Score', sort: 'desc' }] },
                }}
                getCellClassName={(params) => {
                    if (params.type === 'string') {
                        return '';
                    }
                    if (['m', 'weight'].includes(params.field)) {
                        return '';
                    }
                    if (params.value === 0) {
                        return 'error';
                    }

                    if (params.value <= 0.5) {
                        return 'warning';
                    }
                }}


            />
        </Box>
    )
}