import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';


export default function PathLevelTable({ result, model }) {

    const getColumns = () => {
        const columns = [
            { 
                field: 'path', 
                headerName: 'path', 
                width: 180 
            },
            {
                field: 'id',
                width: 100,
                type: 'string',
            },
            {
                field: 'value',
                valueGetter: ({ row }) => {
                    return JSON.stringify(row.value);
                }, 
                width: 250,
                flex: 1
            },
            {
                field: 'Conformant',
                type: 'number',
                width: 90,
            },
            {
                field: 'Complete',
                type: 'number',
                width: 90,
            }
        ]

        if (model === 'dqi-3' || model === 'dqi-4') {
            columns.push({
                field: 'Plausible',
                type: 'number',
                width: 90,
            })
        }

        if (model === 'dqi-4') {
            columns.push({
                field: 'Current',
                type: 'number',
                width: 90,
            })
        }

        

        return columns;
    }

    const rows = result['path_level']

    return (
        <Box>
            <Typography variant="h6" style={{margin: '25px 0'}}>
                Path Level:
            </Typography>
            <DataGrid
                autoHeight
                density='compact'
                getRowId={(row) => row.id + row.path}

                rows={rows}
                columns={getColumns()}
                getCellClassName={(params) => {
                    if (params.type === 'string' || params.value == null) {
                        return '';
                    }
                    if (params.value === 0) {
                        return 'error';
                    }

                    if (params.value < 0.5) {
                        return 'warning';
                    }
                }}
                pageSizeOptions={[25, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                    sorting: {
                        sortModel: [
                            {
                                field: 'path',
                                sort: 'asc',
                            },
                        ],
                    },
                }}

            />
        </Box>
    );
}
