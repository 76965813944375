import React from 'react';
import { Container, Typography } from '@mui/material';

const Footer = () => (
  <footer style={{ padding: '20px 0' }}>
    <Container maxWidth='xl' style={{display: 'flex', justifyContent: 'space-between'}}>
      
      <Typography variant="body1" color="gray">
        Research supported by NCQA, Elevance Health, VA
      </Typography>
      <Typography variant="body1" color="gray">
        Lead Engineer Parker Holcomb
      </Typography>
    </Container>
  </footer>
);

export default Footer;
