import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import GitHubIcon from '@mui/icons-material/GitHub';
import ScienceIcon from '@mui/icons-material/Science';
import BiotechIcon from '@mui/icons-material/Biotech';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import WhatshotIcon from '@mui/icons-material/Whatshot';

import Hidden from '@mui/material/Hidden';




const goto = (url) => {
  console.log("goto", url);
  window.location.href = url;
}


export default function Header() {
  return (
    <Container maxWidth='xl' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

      <Toolbar onClick={() => goto("/")} disableGutters>
        <img src='/images/logo.png' alt="logo" height="30" style={{ marginRight: '10px' }} />

        <Button color="inherit">Clinical Quality Data</Button>

      </Toolbar>

      <Hidden smDown>
        <Toolbar
          id='menu-bar'
          style={{ padding: '25px', flexGrow: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minWidth: '800px' }}
        >
          <Button
            startIcon={<WorkspacesIcon />}
            onClick={() => goto("/coalition")}
            color="inherit"
          >
            Coalition
          </Button>

          <Button
            startIcon={<BiotechIcon />}
            onClick={() => goto("/research")}
            color="inherit"
          >
            Research
          </Button>

          <Button
            color="inherit"
            startIcon={<ScienceIcon />}

          >
            <Link
              href='https://colab.research.google.com/drive/11xOKc-sgaIto5wknpdDkJVWugwpM1GEH?'
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'black', textDecoration: 'none' }}

            >
              Lab
            </Link>
          </Button>
          <Button
            startIcon={<GitHubIcon />} color="inherit"
          >
            <Link
              href='https://github.com/clinicalquality'
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'black', textDecoration: 'none' }}

            >
              GitHub
            </Link>
          </Button>

          <Button
            variant="contained"
            // style={{ margin: '20px' }}
            onClick={() => goto("/example")}
            startIcon={<WhatshotIcon />}
          >
            Get Started
          </Button>
        </Toolbar>
      </Hidden>

      
        
              
      


    </Container >
  );
}