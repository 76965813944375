
import React, { useState } from 'react';
import {
  Typography,
  Box,
  Link,
  Container
} from '@mui/material';

function Abstract() {

  return (
    <>
      <Typography variant='h6' style={{ marginBottom: '15px', textTransform: 'uppercase' }} >
        Abstract.
      </Typography>
      <Typography >
        A pure function implementation of data quality assessment that scores “fitness for use” removes a key blocker to the success of Value Based Contracts: lack of trust in the clinical data used to compute payment. Standards such as HL7 FHIR and the USCDI provide part of the solution, however, measuring data quality must go beyond schema conformance[1]. Here we describe a linear transformation model that takes in a) Population of FHIR Resources, and b) a set of weights that have been “tuned” for use, where by scoring each composite data element for conformance, completeness, and plausibility, effectively yields an m-dimensional data quality index that enables stakeholders to empirically determine the quality and fitness of their population-scale data for a given context.
      </Typography>
    </>
  )

}

function Outcomes() {

  return (
    <>
      <Typography variant='h6' style={{ marginBottom: '15px', textTransform: 'uppercase' }} >
        Outcomes.
      </Typography>
      <Typography >
        Standard of care at 70% confidence (n=50) to 99% confidence (n=)
      </Typography>
    </>
  )

}

function Figures() {

  const images = [
    "/images/fig_data_quality_index.png",
    "/images/figure_index_summary.png",
    "/images/fig_model_architecure.png",
    "/images/fig_fhir_path.png",
    "/images/fig_time_series.png",
    "/images/figure_bulk_vs_bundle.png",
    "/images/fig_index_calculation.png",
  ]


  return (
    <Box style={{ marginTop: '25px' }}>
      <Typography variant='h6' style={{ marginBottom: '15px', textTransform: 'uppercase' }} >
        Results.
      </Typography>
      {
        images.map((image, index) => (
          <Box key={index} style={{ marginBottom: '25px' }}>
            <img src={image} alt="figure" style={{width: '100%'}}/>
          </Box>
        ))
      }


    </Box>
  )

}

function Presentations() {

  const presentations = [
    {
      "title": "Building Trust in Clinical Data for Value Based Care",
      "authors": "Parker Holcomb, Ed Yurcisin",
      "conference": "NCQA Health Innovation Summit",
      "when": "October 2023",
      "url": "https://storage.googleapis.com/dqi-research-cdn/230418-himss23-data-quality-foundations.pdf"
    },
    {
      "title": "Foundations for Measuring Clinical Data Quality",
      "authors": "Brad Ryan, Marc Overhage, Parker Holcomb",
      "conference": "HIMSS",
      "when": "April 2023",
      "url": "https://storage.googleapis.com/dqi-research-cdn/230418-himss23-data-quality-foundations.pdf"
    },
    {
      "title": "Accelerating the Future: Optimizing Public / Private Collaboration to Scale What Works",
      "authors": "Aneesh Chopra",
      "conference": "HIMSS",
      "when": "April 2023",
      "url": "https://storage.googleapis.com/dqi-research-cdn/230417-himss23_chopra.pdf"
    },
    {
      "title": "The Value of High-Quality Data: Fitness and Trust in Clinical Data",
      "authors": "Brad Ryan, Ben Hamlin, Marc Overhage, Parker Holcomb",
      "conference": "NCQA Health Innovation Summit",
      "when": "November 2022",
      "url": "https://www.youtube.com/watch?v=dt-S8tG_oxw&t=120s"
    },
    {
      "title": "Making Clinical Data More Trusted: Validation Frameworks",
      "authors": "Brad Ryan",
      "conference": "HIMSS",
      "when": "March 2022",
      "url": "https://storage.googleapis.com/dqi-research-cdn/220316-himss22-making-clinical-data-more-trusted.pdf"
    },
  ]

  return (
    <Box style={{ marginTop: '25px' }}>
      <Typography variant='h6' style={{ marginBottom: '15px', textTransform: 'uppercase' }} >
        Presentations.
      </Typography>
      {
        presentations.map((presentation, index) => (
          <Box key={index} style={{ marginBottom: '5px' }}>
            <Typography variant='h7'>
              <Link style={{ color: 'black' }} href={presentation.url} target="_blank" rel="noopener noreferrer">{presentation.title}</Link>
            </Typography>
            <Typography variant='body2'>{presentation.authors}</Typography>
            <Typography variant='body2'>{presentation.conference}, {presentation.when}</Typography>
          </Box>
        ))
      }
    </Box>
  )

}

function References() {

  const references = [
    {
      "title": "Electronic health record data quality assessment and tools: a systematic review",
      "authors": "Abigail E Lewis, Nicole Weiskopf, Zachary B Abrams, Randi Foraker, Albert M Lai, Philip R O Payne, Aditi Gupta",
      "where": "Journal of the American Medical Informatics Association",
      "when": "2023",
      "url": "https://academic.oup.com/jamia/article/30/2/299/6315875"
    },
    {
      "title": "A Harmonized Data Quality Assessment Terminology and Framework for the Secondary Use of Electronic Health Record Data",
      "authors": "Michael G Kahn 1, Tiffany J Callahan 1, Juliana Barnard 1, Alan E Bauck 2, Jeff Brown 3, Bruce N Davidson 4, Hossein Estiri 5, Carsten Goerg 1, Erin Holve 6, Steven G Johnson 7, Siaw-Teng Liaw 8, Marianne Hamilton-Lopez 9, Daniella Meeker 10, Toan C Ong 9, Patrick Ryan 11, Ning Shang 12, Nicole G Weiskopf 13, Chunhua Weng 12, Meredith N Zozus 14, Lisa Schilling 9",
      "journal": "EGEMS (Washington, DC)",
      "where": "2016",
      "url": "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5051581"
    }
  ]

  return (
    <Box style={{ padding: '25px' }}>
      <Typography variant='h6' style={{ marginBottom: '15px', textTransform: 'uppercase' }} >
        References.
      </Typography>
      {
        references.map((presentation, index) => (
          <Box key={index} style={{ padding: '5px', marginBottom: '5px' }}>
            <Typography variant='h7'>
              <Link href={presentation.url} target="_blank" rel="noopener noreferrer">{presentation.title}</Link>
            </Typography>
            <Typography variant='body2'>{presentation.authors}</Typography>
            <Typography variant='body2'>{presentation.where}, {presentation.when}</Typography>
          </Box>
        ))
      }

    </Box>
  )

}

export default function Research() {



  return (
    <Container>

      <Abstract />
      <Presentations />
      <Figures />

      <References />

    </Container>
  )
}
